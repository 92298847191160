import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useCookieInit } from '../hooks/useCookieInit';
import Head from './Head';
import Footer from './partials/Footer';
import PreviewModal from './partials/PreviewModal';

export default function BlogLayout({
  alternativePages,
  head,
  footer,
  mode,
  children,
  meta,
  previewData,
}) {
  const { isDevelopment } = mode;

  useCookieInit();

  useEffect(() => {
    if (meta?.page?.useDesignSystem2?.[0] === false) {
      document.querySelector('body').classList.add('-ds2');
    } else {
      document.querySelector('body').classList.remove('-ds2');
    }
  }, []);

  const cookies = new Cookies();
  const activeExperiments = cookies.get('rippling_experiments') || [];

  const isHidden = false;
  const experimentNames =
    (activeExperiments &&
      activeExperiments.map((experiment) => experiment.name).join(' ')) ||
    '';
  const { schema } = meta?.seo || {};

  return (
    <div className={`container ${isHidden ? 'hidden' : ''} ${experimentNames}`}>
      <Head head={head} alternativePages={alternativePages} />

      {!!schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: schema }}
        />
      )}

      {children}

      {isDevelopment && <PreviewModal previewData={previewData} />}

      <Footer blog={true} {...footer} />
    </div>
  );
}
